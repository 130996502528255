'use strict';

require('../../modules/es.typed-array.int8-array');
require('../../modules/es.typed-array.uint8-array');
require('../../modules/es.typed-array.uint8-clamped-array');
require('../../modules/es.typed-array.int16-array');
require('../../modules/es.typed-array.uint16-array');
require('../../modules/es.typed-array.int32-array');
require('../../modules/es.typed-array.uint32-array');
require('../../modules/es.typed-array.float32-array');
require('../../modules/es.typed-array.float64-array');
require('./methods');
module.exports = require('../../internals/global-this');